
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

@include for-size(phone-portrait-down) {
  .page__faq-article__docs {
    margin-left: 0;
  }
}
